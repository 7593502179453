import SimplePeer from './simplepeer.min.js';

export default {

  // WEBRTC **********************
  startWebRtc: async function (initiator, signalService, collabRoom) {

    collabRoom.webRtcConnected = false;

    console.log("starting WebRtc peering");
    const peer = new SimplePeer({
      initiator: initiator,
      trickle: false,
      stream: collabRoom.stream,
      config: {
        iceServers: [
          {
            urls: "stun:relay.metered.ca:80",
          },
          {
            urls: "turn:relay.metered.ca:80",
            username: "f673bf4a64c4c37d94c80ff1",
            credential: "bKIyamKcFg5K8tDy",
          },
          {
            urls: "turn:relay.metered.ca:443",
            username: "f673bf4a64c4c37d94c80ff1",
            credential: "bKIyamKcFg5K8tDy",
          },
          {
            urls: "turn:relay.metered.ca:443?transport=tcp",
            username: "f673bf4a64c4c37d94c80ff1",
            credential: "bKIyamKcFg5K8tDy",
          },
        ],
      },
    });


    collabRoom.connection.on('signal', async (message) => {
      // Ignore if this is the sender of message
      if (message.sender == collabRoom.clientId) {
        return
      };
      console.log("Peer signal received from " + message.sender + "  for client ID = " + collabRoom.clientId);
      peer.signal(JSON.parse(message.content));
    })

    peer.on('signal', (data) => _sendSignal(data));
    peer.on('connect', () => _handleConnection());
    peer.on('error', (err) => _handleError(err));
    peer.on('stream', (stream) => _handleStream(stream));
    peer.on('data', (data) => _handleData(data));
    peer.on('close', () => _handleClose());

    return collabRoom;

    // Event Handlers
    async function _sendSignal(data) {
      console.log("WebRtc signal data");
      console.log(data);
      await signalService.sendMessageToGroup(collabRoom.roomId, JSON.stringify(data), "signal");
    };

    function _handleError(err) {
      console.log('WebRtc error', err)
    };

    function _handleConnection() {
      console.log('CONNECTED via WebRtc')
      peer.send(`Message from ${collabRoom.clientId} : Connected ${Math.random()}`)
      collabRoom.webRtcConnected = true;
    };

    function _handleStream(stream) {
      // got remote video stream, now let's show it in a video tag
      var video = document.getElementById('webRtcShareElement')
      if (!video) console.error("No video element found for WebRtc stream");

      if ('srcObject' in video) {
        video.srcObject = stream
      }
      else {
        // old version
        // @ts-ignore
        video.src = window.URL.createObjectURL(stream)
      }

    };

    function _handleData(data) {
      console.log('webrtc peer data: ' + data)
    }

    async function _handleClose() {
      console.log('WebRtc Closing Connection');
    }

    async function _handleRemoteHangup() {
      console.log('WebRtc Handling remote hangup');
    }

  }

}
