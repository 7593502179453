/// LIVE SHARE module
import axios from 'axios';
import * as signalR from '@microsoft/signalr';
import SignallingService from './signallingService';
import WebRtcService from './webRtc';

let signallingService = new SignallingService(axios, signalR);
let collabRoomState = {
  clientId: null,
  roomId: null,
  shareLink: '',
  connection: null,
  stream: null,
  webRtcConnected: false,
  singallingConnected: false,
  liveShareInitiated: false,
}


export default {


  getState() {
    return collabRoomState;
  },

  async startRoomShare() {
    const roomId = window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16);
    const collabRoom = await this._setupSignalling(roomId);

    // Capture screen and attach to room object for sharing via webRtc.
    // @ts-ignore

    try {
      collabRoom.stream = await navigator.mediaDevices
        .getDisplayMedia({
          video: { displaySurface: "browser" },
          audio: false,
          preferCurrentTab: true
        });
      if (collabRoom.stream.readyState === 'ended') {
        console.log('User cancelled the share media dialog');
      } else {
        console.log('User shared media successfully');

      }
    } catch (error) {
      console.error('An error occurred while accessing display media:', error);
    };



    collabRoom.stream.getVideoTracks()[0].onended = () => {
      signallingService.sendMessageToGroup(collabRoom.roomId, "", "screenShareStopped");
    };

    // @ts-ignore
    collabRoom.connection.on('userJoinedRoom', async (uid) => {
      // When the receiver comes online and joins the room , start WebRtc peering.
      console.log(`user ${uid} created room ${roomId} `);
      // Start webrtc
      await WebRtcService.startWebRtc(true, signallingService, collabRoom);
    });

    collabRoomState = collabRoom;
    collabRoomState.liveShareInitiated = true;

    return collabRoom;
  },

  async joinRoomShare(roomId) {
    let collabRoom = await this._setupSignalling(roomId);
    if (collabRoom === null) console.error("Signalling failed");

    collabRoom = await WebRtcService.startWebRtc(false, signallingService, collabRoom);

    // Wait for WebRtc connection to be established.
    while (!collabRoom.webRtcConnected) {
      await new Promise(resolve => setTimeout(resolve, 500));
    }

    // Todo add catch to handle error if webRtc does not connect within X seconds
    collabRoomState = collabRoom;
    return collabRoomState;
  },


  async _setupSignalling(roomId) {
    if (!roomId) return console.log('No room id provided');
    console.log('Setting up signalling for room ' + roomId);
    const clientId = window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16);
    let collabRoom = await signallingService.createOrJoinRoom(clientId, roomId);
    collabRoomState = collabRoom;
    return collabRoom;
  }


}
