// SignalR services for webRtc signalling
//  Use:
class SignallingService {

    connection: any;
    apiBaseUrl: string;
    ClientId: string | null;
    axios: any;
    signalR: any;

    constructor(axios: any, signalR: any) {
        this.axios = axios;
        this.signalR = signalR;
        this.apiBaseUrl = window.location.origin;
        this.connection;
        this.ClientId = null;

        console.log("Signalling Service Started");
    }

    // Start Chat room and join a new group
    async createOrJoinRoom(clientId: string, roomId: string): Promise<{
        clientId: string;
        roomId: string;
        shareLink: string;
        connection: signalR.HubConnection;
        stream: any;
        webRtcConnected: boolean;
        singallingConnected: boolean;
    }> {
        this.ClientId = clientId;
        console.log("Start Room ID: " + roomId);
        this.axios.defaults.headers.post['x-vsft-uniqueid'] = clientId;
        //  start a signalR negotiation
        //  connect direct to signal server
        this.connection = await this._connectSignalR(this.apiBaseUrl, clientId);
        //  add this user to a new group using roomId
        let groupResponse = await this.addUserToGroup(roomId);

        console.log(`Attempt to join user ${clientId} to room: ${roomId}`)
        console.log(groupResponse);

        //  generate a share link to distribute
        const shareLink = this.generateShareLink(roomId);

        return {
            clientId: clientId,
            roomId: roomId,
            shareLink: shareLink,
            connection: this.connection,
            stream: null,
            webRtcConnected: false,
            singallingConnected: this.connection && this.connection.state === this.signalR.HubConnectionState.Connected
        }
    };


    ///
    /// Send a message to the group
    /// Returns a promise with message result;
    async sendMessageToGroup(
        roomId: string,
        messageText: string,
        targetAction = "newMessage"
    ): Promise<any> {
        try {
            let response = await this.axios.post(`${this.apiBaseUrl}/api/sendMessage`, {
                roomId: roomId,
                TargetAction: targetAction,
                MessageData: {
                    sender: this.ClientId,
                    content: messageText
                }
            })
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    ///
    /// Send a message to the group
    /// Returns a promise with message result;
    async sendMessageToClient(
        targetClientId: string,
        messageText: string,
        targetAction = "newMessage"
    ): Promise<any> {
        try {
            let response = await this.axios.post(`${this.apiBaseUrl}/api/sendMessage`, {
                ClientId: targetClientId,
                TargetAction: targetAction,
                MessageData: {
                    sender: this.ClientId,
                    text: messageText
                }
            })
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    async addUserToGroup(roomId: string): Promise<any> {
        try {
            const response = await this.axios.post(`${this.apiBaseUrl}/api/joinRoom`, { roomId: roomId });
            return response.data;
        }
        catch (err) {
            console.log(err);

        }
    };

    generateShareLink(roomId: string): string {
        const url = new URL(`/liveshare/${roomId}`, window.location.origin);
        console.log("Share link: " + url.toString());
        return url.toString();
    };



    /// Setup SignalConnection using new UserID
    _connectSignalR = async (apiBaseUrl, clientId) => {
        try {
            const info = await this.axios.post(`${apiBaseUrl}/api/negotiate`, null, {
                // Add custom header for unique anon user id
                headers: { 'x-vsft-uniqueid': clientId }
            });
            const options = {
                accessTokenFactory: () => info.data.accessToken
            };

            // Create hub connection
            this.connection = new this.signalR.HubConnectionBuilder()
                .withUrl(info.data.url, options)
                .configureLogging(this.signalR.LogLevel.Information)
                .configureLogging(this.signalR.LogLevel.Error)
                .build();

            this.connection.onreconnecting(() => console.log('singalling reconnecting'));
            this.connection.onreconnected(() => console.log('singalling reconnected'));
            this.connection.onclose(() => console.log('signalling disconnected'));
            await this.connection.start();
            return this.connection;

        } catch (err) {
            console.log("Error connecting to Signalling server");
            console.log(err);
            return false;
        }
    };
}

export default SignallingService;
