import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { DicomMetadataStore, MODULE_TYPES } from '@ohif/core';

import Dropzone from 'react-dropzone';
import filesToStudies from './filesToStudies';

import { extensionManager } from '../../App.tsx';

import { Icon, Button, LoadingIndicatorProgress } from '@ohif/ui';



function Local() {
    const navigate = useNavigate();
    const dropzoneRef = useRef();
    const [dropInitiated, setDropInitiated] = React.useState(false);

    // Initializing the dicom local dataSource
    const dataSourceModules = extensionManager.modules[MODULE_TYPES.DATA_SOURCE];
    const localDataSources = dataSourceModules.reduce((acc, curr) => {
        const mods = [];
        curr.module.forEach(mod => {
            if (mod.type === 'localApi') {
                mods.push(mod);
            }
        });
        return acc.concat(mods);
    }, []);

    const firstLocalDataSource = localDataSources[0];
    const dataSource = firstLocalDataSource.createDataSource({});

    const onDrop = async acceptedFiles => {
        const studies = await filesToStudies(acceptedFiles, dataSource);
        // Todo: navigate to work list and let user select a mode
        const query = new URLSearchParams();
        studies.forEach(id => query.append('StudyInstanceUIDs', id));
        navigate(`/viewer/dicomlocal?${decodeURIComponent(query.toString())}`);
    };

    const onDemoBtn = async () => {
        console.log('onDemoBtn clicked for viewing ');
        navigate("/viewer/dicomjson?url=/LIDC-IDRI-0001.json");
    }

    const getLoadButton = (onDrop, text, isDir) => {
        return (
            <Dropzone onDrop={acceptedFiles => {
                setDropInitiated(true);
                onDrop(acceptedFiles);
            }} noDrag>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <Button
                            rounded="full"
                            variant="contained" // outlined
                            disabled={false}
                            endIcon={<Icon name="launch-arrow" />} // launch-arrow | launch-info
                            className={classnames('font-medium', 'ml-2')}
                            onClick={() => { }}
                        >
                            {text}
                            {isDir ? (
                                <input
                                    {...getInputProps()}
                                    webkitdirectory="true"
                                    mozdirectory="true"
                                />
                            ) : (
                                <input {...getInputProps()} />
                            )}
                        </Button>
                    </div>
                )}
            </Dropzone>
        );
    };

    // Set body style
    useEffect(() => {
        document.body.classList.add('bg-black');
        return () => {
            document.body.classList.remove('bg-black');
        };
    }, []);

    return (
        <Dropzone
            ref={dropzoneRef}
            onDrop={acceptedFiles => {
                setDropInitiated(true);
                onDrop(acceptedFiles);
            }
            }
            noClick
        >
            {({ getRootProps }) => (
                <div {...getRootProps()} style={{ width: '100%', height: '100%' }}>
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        backgroundColor: '#ff9900',
                        color: '#fff',
                        padding: '10px',
                        textAlign: 'center',
                    }}>
                        <a href="https://share.dicomviewer.net/?ref=dicomviewer" style={{
                            margin: 'auto',
                            color: '#ffffff',
                            textDecoration: 'underline'
                        }}>
                            Upload and Share your Dicom Images - Click here &#8594;
                        </a>
                    </div>

                    <div className="h-screen w-screen flex justify-center items-center ">
                        <div className="py-8 px-8 mx-auto drop-shadow-md space-y-2 rounded-lg">
                            <img
                                className="block mx-auto h-14"
                                src="./assets/vuresoft_colour_icon.png"
                                alt="OHIF"
                            />
                            <div className="text-center space-y-2 pt-4">
                                {dropInitiated ? (
                                    <div className="flex flex-col items-center justify-center pt-48">

                                        <LoadingIndicatorProgress
                                            className={'w-full h-full bg-black'}
                                            textBlock={<p className='text-white'>Loading images, please wait...</p>}
                                        />
                                    </div>
                                ) : (
                                    <div className="space-y-2">
                                        <p className="text-2xl text-white font-semibold pt-6 pb-3">
                                            Drag and Drop DICOM files here to load them in the Viewer
                                        </p>

                                        <p className="text-blue-300 text-base">
                                            (Hint: you can drop an entire folder on this page)</p>
                                        <p className="text-blue-300 text-lg">Or click to </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-around pt-4 ">
                                {getLoadButton(onDrop, 'Load files', false)}
                                {getLoadButton(onDrop, 'Load folders', true)}
                            </div>
                            <div className="text-center pt-6">
                                <p className="text-blue-300 text-base pt-6">
                                    No data is uploaded to any server.
                                    All processing happens locally within this browser.
                                    <br>
                                    </br>
                                    Loading may take a few minutes when selecting large studies.
                                </p>
                            </div>

                            <div className="text-center pt-6 mt-12">
                                <p className="text-blue-100 text-base pt-6">
                                    Don't have any DICOM files?
                                    Click the button below to view some demo images.
                                </p>
                                <Button onClick={onDemoBtn} className="text-base px-2 py-2 mt-5 bg-blue-100">
                                    View Demo Images
                                </Button>
                            </div>
                        </div>
                    </div>
                </div >
            )
            }
        </Dropzone >

    );
}

export default Local;
