import { LoadingIndicatorProgress } from '@ohif/ui';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { commandsManager, servicesManager } from '../../App.tsx';


/// This is the component that will be rendered when the user navigates to the /liveshare route
/// This is the remote user page when they have been sent a share link to see the source users images.
function LiveShare() {

    const [isLoading, setLoading] = useState(false);
    const [roomClosed, setRoomClosed] = useState(false);
    const [connectionEstablished, setConnectionEstablished] = useState(false);
    const { roomId } = useParams();

    const { LiveShareService } = servicesManager.services;

    const sharedScreen = () => {
        return (
            <React.Fragment>
                <div>
                    <div className="flex flex-col h-full justify-center items-center"
                        style={{ display: connectionEstablished ? "flex" : "none" }}>
                        <video id="webRtcShareElement" autoPlay muted playsInline
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '10px',
                                border: '5px solid white',
                            }}
                        />
                    </div>
                    <div className="flex flex-col items-center justify-center pt-48" style={{ display: connectionEstablished ? "none" : "flex" }}>
                        <LoadingIndicatorProgress
                            className={'w-full h-full bg-black'}
                            textBlock={<p className='text-white'>Connecting to LiveShare Room, please wait...</p>}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    };

    const roomClosedScreen = () => {
        return (
            <React.Fragment>
                <div className="flex flex-col items-center justify-center pt-48">
                    <p className='text-white'>LiveShare Room has been closed.</p>
                </div>
            </React.Fragment>
        )
    };


    useEffect(() => {
        if (roomId && roomId.length >= 5) {
            setLoading(true);
            LiveShareService.joinRoomShare(roomId)
                .then((result) => {
                    setLoading(false);
                    setRoomClosed(false);
                    if (result.webRtcConnected == true) {
                        console.log("WebRTC connection established");
                        setConnectionEstablished(true);
                    };
                    result.connection.on("screenShareStopped", () => {
                        console.log("Screen share stopped");
                        setRoomClosed(true);
                    });
                })
        };
    }, []);

    if (!roomId || roomId.length < 5) {
        return (
            <div className="flex flex-col h-full justify-center items-center">
                <h1 className='text-white'>Error - no valid room id provided.</h1>
            </div>
        )
    }
    else {
        return roomClosed ? roomClosedScreen() : sharedScreen()
    }
}

export default LiveShare;
