const DCM_CODE_VALUES = {
  IMAGING_MEASUREMENTS: '126010',
  MEASUREMENT_GROUP: '125007',
  IMAGE_REGION: '111030',
  FINDING: '121071',
  TRACKING_UNIQUE_IDENTIFIER: '112039',
  LENGTH: '410668003',
  AREA: '42798000',
  SHORT_AXIS: 'G-A186',
  LONG_AXIS: 'G-A185',
  ELLIPSE_AREA: 'G-D7FE', // TODO: Remove this
};

export default DCM_CODE_VALUES;
