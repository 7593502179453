import React, { useState } from 'react';
import { Button, LoadingIndicatorProgress } from '@ohif/ui';
import { QRCodeSVG } from 'qrcode.react';

export function LiveSharePanel({ servicesManager, commandsManager }) {
  const { LiveShareService } = servicesManager.services;
  let currentState = LiveShareService.getState();
  console.log("Current room state: ", currentState);


  const [shareLink, setShareLink] = useState(currentState.shareLink);
  const [streamActive, setStreamActiveStatus] = useState(currentState.webRtcConnected);
  const [liveShareInitiated, setLiveShareInitiated] = useState(currentState.liveShareInitiated);

  async function startSession() {
    setLiveShareInitiated(true);
    let collabRoom = await LiveShareService.startRoomShare();
    setShareLink(collabRoom.shareLink);
  }


  const shareButton = () => {
    return (
      <React.Fragment>
        <div className="flex justify-center px-2 py-1 mt-5">
          <Button className="px-2 py-2 text-base" onClick={startSession} disabled={liveShareInitiated}>
            {('Start LiveShare')}
          </Button>
        </div>
      </React.Fragment>
    )
  }
  const shareDetails = () => {
    return (
      <React.Fragment>
        <div className="flex transition duration-300 bg-black border border-transparent cursor-default group">
          <div className="flex items-center justify-between flex-1 px-2 py-4  text-primary-light">
            <span className="text-primary-light mb-3">
              <p className="mb-2 font-weight-bold">Here is your share link:</p>
              <a href={shareLink}
                className="text-white text-sm items-center justify-center mb-3"
                target='_blank'>{shareLink}</a>
              <br />
              <div className='mt-3'>
                <QRCodeSVG value={shareLink} />
              </div>
            </span>
          </div>
          <div>
          </div>
        </div>
      </React.Fragment>
    )
  }
  const loadingBar = () => {
    return (
      <div style={{ width: '80%', position: 'relative' }}>
        <LoadingIndicatorProgress
          textBlock={<p className='text-white'>Starting LiveShare, please wait...</p>} />
      </div>
    )
  }

  return (
    <div>
      <div className="flex justify-center px-2 py-1 bg-secondary-main">
        <span className="text-base font-bold tracking-widest text-white uppercase">
          Live Share
        </span>
      </div>
      <div className="flex transition duration-300 bg-black border border-transparent cursor-default group">
        <div className="flex items-center justify-between flex-1 px-2 py-4  text-primary-light">
          Live Share allows you to share your screen with a remote user in real-time.
          <br />  Simply share the link or QR code below and they will be able to see the images you're viewing.
          This feature is currently free while in beta and only supports one remote user.
        </div>
      </div>
      {!liveShareInitiated ? shareButton() : null}
      {liveShareInitiated && shareLink.length > 1 ? shareDetails() : null}
      {liveShareInitiated && shareLink.length < 1 ? loadingBar() : null}
    </div>
  )
};
